<template>
    <div v-if="loadedStatus" class="shadow-2 border-round p-3" :style="{
                background:
                    timeSinceLastUpdateMs > 3600000 * 1.00 ? '#cc3232' :
                    timeSinceLastUpdateMs > 3600000 * 0.75 ? '#db7b2b' :
                    timeSinceLastUpdateMs > 3600000 * 0.50 ? '#e7b416' :
                    timeSinceLastUpdateMs > 3600000 * 0.25 ? '#99c140' : '#2dc937'
                }">
        <div class="py-3 fadein">
            <div class="flex justify-content-center" >
                <p class="font-bold text-2xl my-1">{{ timeSinceLastUpdate }}</p>
            </div>
        </div>
    </div>
    <div v-else class="surface-card shadow-2 border-round p-3">
        <div class="grid py-3">
            <div class="col-12 flex justify-content-center text-black-alpha-90">
                <p class="font-bold text-2xl my-1">Loading...</p>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "CyberlockerHeartbeat",

    data() {
        return {
            loading: false,
            loadedStatus: false,
            attempts: [],
            asset_links: [],
            timeSinceLastUpdateMs: null,
            timeSinceLastUpdate: null,

            graphTimer: null,
        }
    },

    mounted() {
        this.updateCaseLifeTime();
        this.getCyberlockerOverview();

        this.graphTimer = setInterval(() => {
            this.getCyberlockerOverview();
        }, 5000);
    },

    beforeUnmount() {
        console.debug("Unmounting CyberlockerHeartbeat");
        clearInterval(this.graphTimer);
    },

    methods: {
        updateCaseLifeTime() {
            setInterval(() => {
                // Update the computed property
                this.timeSinceLastUpdate = this.calculateTimeSinceLastUpdate();
            }, 1000);
        },

        getCyberlockerOverview() {
            this.loading = true;
            this.$factory.cyberlocker.getOverview().then((response) => {
                console.debug(response)
                this.attempts = response.attempts;
                this.asset_links = response.asset_links;

                this.loadedStatus = true;
            }).catch((error) => {
                console.error(error);
                this.$emitter.emit("bad-toast", "Failed to get cyberlocker heartbeat")
            }).finally(() => {
                this.loading = false;
            });
        },

        calculateTimeSinceLastUpdate() {
            if (this.attempts.length === 0) {
                return '...';
            }
            const lastUpdate = new Date(this.attempts[0].UTC_DateCreated + 'Z')
            const currentDate = new Date().getTime();

            this.timeSinceLastUpdateMs = currentDate - lastUpdate;

            const days = Math.floor(this.timeSinceLastUpdateMs / (1000 * 60 * 60 * 24));
            const hours = Math.floor((this.timeSinceLastUpdateMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((this.timeSinceLastUpdateMs % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((this.timeSinceLastUpdateMs % (1000 * 60)) / 1000);

            return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
        }

    }
}
</script>

<style scoped>

</style>
