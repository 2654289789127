<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <div class="col-12">
                <CyberlockerHeartbeat/>
            </div>

            <div class="col-12">
                <CyberlockerPastWeek/>
            </div>

<!--            <div class="col-12">-->
<!--                <CyberlockerNetworkGraph/>-->
<!--            </div>-->

            <div class="col-12">
                <CyberlockerReport/>
            </div>

            <div class="col-12">
                <CyberlockerOverview/>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import CyberlockerReport from "@/components/graphs/cyberlockers/CyberlockerReport";
import CyberlockerOverview from "@/components/graphs/cyberlockers/CyberlockerOverview";
import CyberlockerHeartbeat from "@/components/graphs/cyberlockers/CyberlockerHeartbeat";
import CyberlockerPastWeek from "@/components/graphs/cyberlockers/CyberlockerPastWeek";
// import CyberlockerNetworkGraph from "@/components/graphs/cyberlockers/CyberlockerNetworkGraph";
export default {
    name: "Cyberlockers",
    components: {
        CyberlockerReport,
        CyberlockerOverview,
        CyberlockerHeartbeat,
        CyberlockerPastWeek,
        // CyberlockerNetworkGraph
    },

    methods: {

    }
}
</script>

<style scoped>

</style>
