<template>
    <div class="surface-card shadow-2 border-round p-3">
        <DataTable :value="cyberlockers" :loading="loading" class="w-full">
            <Column field="asset_name" header="Asset" ></Column>
            <Column field="unique_cyberlocker" header="Unique Cyberlockers"></Column>
            <Column field="total_cyberlocker" header="Total Cyberlockers" ></Column>
            <Column field="crawled_links" header="Links Processed"></Column>
            <Column field="potential_links" header="Links to Process" ></Column>
            <Column value="Delete" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                <template #body="{ data }">
                    <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                        <i class="pi pi-bolt" @click="resetCyberlocker($event, data)"/>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
export default {
    name: "CyberlockerReport",

    data() {
        return {
            loading: false,
            cyberlockers: [],

            graphTimer: null,
        }
    },

    mounted() {
        this.getCyberlockers();

        this.graphTimer = setInterval(() => {
            this.getCyberlockers();
        }, 60000);
    },

    methods: {
        getCyberlockers() {
            this.loading = true;
            // TODO convert ID to asset name - AssetName
            this.$factory.cyberlocker.getAllCyberlockers().then((response) => {
                console.debug(response)
                this.cyberlockers = response;
            }).catch((error) => {
                console.error(error);
                this.$emitter.emit("bad-toast", "Failed to get cyberlockers")
            }).finally(() => {
                this.loading = false;
            });
        },

        resetCyberlocker(event, data) {
            console.debug(event, data);
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to reset this entry?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$factory.cyberlocker.resetCyberlockers(data.AssetId).then(() => {
                        this.getCyberlockers();
                        this.$emitter.emit('good-toast', data.asset_name + ' reset');
                    }).catch(error => {
                        console.error(error);
                        this.$emitter.emit('bad-toast', 'Failed to reset ' + data.asset_name);
                    })
                },
            });
        },
    }
}
</script>

<style scoped>

</style>
