<template>
<div class="surface-card shadow-2 border-round p-3">
    <div class="grid">
        <div class="col-6">
            <DataTable :value="cyberlockers.attempts" :loading="loading" class="w-full" paginator :rows="5">
                <Column field="Id" header="Id"/>
                <Column field="Link" header="Link"/>
                <Column field="UTC_DateCreated" header="UTC_DateCreated"/>
            </DataTable>
        </div>

        <div class="col-6">
            <DataTable :value="cyberlockers.asset_links" :loading="loading" class="w-full" paginator :rows="5">
                <Column field="Id" header="Id"/>
                <Column field="Domain" header="Domain"/>
<!--                <Column field="Link" header="Link"/>-->
                <Column field="UTC_DateCreated" header="UTC_DateCreated"/>
            </DataTable>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "CyberlockerOverview",

    data() {
        return {
            loading: false,
            cyberlockers: [],

            graphTimer: null,
        }
    },

    mounted() {
        this.getCyberlockerOverview();

        this.graphTimer = setInterval(() => {
            this.getCyberlockerOverview();
        }, 60000);
    },

    methods: {
        getCyberlockerOverview() {
            this.loading = true;
            this.$factory.cyberlocker.getOverview().then((response) => {
                console.debug(response)
                this.cyberlockers = response;
            }).catch((error) => {
                console.error(error);
                this.$emitter.emit("bad-toast", "Failed to get cyberlockers")
            }).finally(() => {
                this.loading = false;
            });
        },

    }
}
</script>

<style scoped>

</style>
